.cookie-settings-modal-container {
  z-index: 1102;
  position: fixed;
  top: 21vh;
  left: 25vw;
  align-self: center;
  justify-self: center;

  min-height: 200px;
  width: 50%;
  padding: 30px;
  color: black;
  background-color: white;
  opacity: 0.96;
  border-radius: 10px;
  box-shadow: 0px 0px 15px 14px rgba(0, 0, 0, 0.14), 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 1px 5px 0 rgba(0, 0, 0, 0.12);
  @media (max-width: 1200px) {
    width: 96%;
    top: 5vh;
    left: 2vw;
    padding: 20px 10px;
  }
  .title-container {
    font-size: 20px;
  }

  .body-container {
    display: flex;
    flex-direction: row;
    @media (max-width: 800px) {
      flex-direction: column;
    }
    .menu {
      @media (max-width: 800px) {
        flex-direction: row;
        max-width: max-content;
        button {
          border: 1px solid #e0e0e0 !important;
          // border-radius: 10px;
        }
      }
      .menu-button {
        width: 200px;
      }
      .cookie-title {
        font-size: 14px;
        color: #494949;
        //font-family: Suisse_Int_l_SemiBold;
        line-height: 18px;
        margin: 10px 0px;
        text-transform: initial;
        @media (max-width: 400px) {
          font-size: 12px;
        }
      }
    }
    .content {
      overflow: scroll;
      height: 300px;
      margin: 20px;
      padding-right: 12px;
      .text1 {
        font-size: 14px;
        color: #494949;
        //font-family: Suisse_Int_l_Regular;
        line-height: 20px;
        margin-bottom: 1rem;
      }
      .textLink {
        font-size: 16px;
        color: #494949;
        //font-family: Suisse_Int_l_SemiBold;
        line-height: 20px;
        text-decoration: underline;
        margin-bottom: 1rem;
        &:hover {
          cursor: pointer;
          color: #096dba;
        }
      }
      .select p {
        text-align: end;
       color: #096dba;
      }
    }
  }

  .buttons-container {
    display: flex;
    justify-content: flex-end;
    margin: 20px;

    .save-button,
    .cancel-button {
      color: #ffffff;
      background-color: #096dba;
      border-radius: 6px;
      font-size: 14px;
      //font-family: Suisse_Int_l_Bold;
      display: flex;
      margin: 10px 10px;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 30px;
      width: 145px;
      border: none;
    }
    .save-button:hover,
    .cancel-button:hover {
      color: #096dba;
      background-color: #ffffff;
      border: none;
    }
  }
}

.react-toggle--checked .react-toggle-track {
  background-color: #096dba;
}

.react-toggle--checked:hover:not(.react-toggle--disabled) .react-toggle-track {
  background-color: #075b9b;
}

.react-toggle--checked .react-toggle-thumb {
    left: 27px;
    border-color: #096dba;
}
