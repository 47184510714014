
.ReactModalPortal div {
  z-index: 2;
}

.video-mobile {
  font-family: sans-serif;
  text-align: center;
  display: flex;
  justify-content: center;
  margin: 44px auto;
}

.video-options-container {
  background-color: #494949;
  display: flex;
  margin: 0 auto;
  align-items: center;
  padding: 0px 0px 18px 0px;
  -webkit-box-shadow: 12px 12px 28px 0px rgba(0,0,0,0.75);
  -moz-box-shadow: 12px 12px 28px 0px rgba(0,0,0,0.75);
  box-shadow: 12px 12px 28px 0px rgba(0,0,0,0.75);
  border-radius: 12px;

  .video-options-subcontainer {
    margin: auto;
    max-width: 1165px;
    display: flex;
    flex-direction: column;

    .div-close {
      display: flex;
      justify-content: flex-end;
      padding: 0px 19px 19px 10px;

      .button-close {
        border: 0;
        background: #096dba;
        color: #ffffff;
        border-radius: 5px;
        padding: 0.5rem 1rem;
        font-size: 0.8rem;
        line-height: 1;
        width: 70px;
      }
    }

    .text-container {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      border-left: 6px solid #ffffff;
      padding: 0px 0px 5px 16px;
      .text-1 {
        font-size: 25px;
        margin: 0;
        color: #ffffff;
        line-height: 30px;
      }
      .text-2 {
        font-size: 18px;
        margin: 0;
        color: #ffffff;
      }
    }

    .radio-inputs-container {
      display: flex;
      align-items: center;
      padding: 4px 0px;
      .text-choose {
        //font-family: Suisse_Int_l_SemiBold;
        font-size: 12px;
        color: #ffffff;
        margin: 0 15px;
      }
      .radio-input-container {
        display: grid;
        grid-auto-flow: column;
        align-items: center;
        opacity: 0.4;
      }
      .selected {
        opacity: 1;
        transform: scale(1.05);
      }
      input[type="radio"] {
        display: none;
      }
      label {
        display: inline-block;
        cursor: pointer;
        text-align: center;
        //font-family: verdana;
        text-transform: capitalize;
        font-size: 10px;
        line-height: 1.5em;
        margin: 0 5px;
        img {
          height: 50px;
          width: 50px;
          overflow: hidden;
          display: block;
          // &:hover {
          //   opacity: 0.75;
          // }
        }
      }
    }

    .form-container {
      display: block;
      // justify-content: space-evenly;
      padding: 9px 2px 8px 2px;
      width: 100%;
      //   background-color: #096dba;
      color: #e2f6fc;
      flex-wrap: wrap;

      .form-container-1 {
        display: flex;
        flex-direction: row;
      }

      .select-container {
        //font-family: SuisseIntl-Book;
        font-size: 14px;
        color: #dce3e8;

        svg {
          text-anchor: middle;
          font-size: 22.034px;
          fill: #fffefe;
          //font-family: Material-Design-Iconic-Font, Material-Design-Iconic-Font;
          position: absolute;
          top: 27px;
          right: 26px;
        }

        select {
          padding: 0px 10px;
          border: 2px solid #818181;
          color: #494949;
          //font-family: SuisseIntl-Book;
          box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
          background-color: #ffffff;
          // background-size: 0.65em auto, 100%;
          height: 30px;
          width: 150px;
          margin: 6px 10px 7px 10px;
          border-radius: 10px;
          cursor: pointer;
        }

        .select-placeholder {
          color: #929292;
        }
        .select-error {
          border: 2px solid #ff0000;
        }
      }

      .text-input {
        //font-family: SuisseIntl-Book;
        font-size: 14px;
        padding: 0px 10px;
        border: 2px solid #818181;
        color: #494949;
        box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
        background-color: #ffffff;
        // background-size: 0.65em auto, 100%;
        height: 30px;
        width: 150px;
        margin: 6px 10px 7px 10px;
        border-radius: 10px;
        cursor: pointer;

        &::placeholder {
          /* Chrome, Firefox, Opera, Safari 10.1+ */
          color: #929292;
          opacity: 1; /* Firefox */
        }
      }
      .input-url {
        width: 320px;
        max-width: 100%;
      }

      // .input-placeholder {
      //   color: #adadad;
      // }
      .input-error {
        border: 2px solid #ff0000;
      }
      // .text-input-1 {
      //   font-family: SuisseIntl-Book;
      //   font-size: 14px;
      //   padding: 0px 10px;
      //   border: 2px solid #818181;
      //   color: #494949;
      //   box-shadow: 0 1px 0 1px rgba(0, 0, 0, 0.03);
      //   background-color: #ffffff;
      //   // background-size: 0.65em auto, 100%;
      //   height: 30px;
      //   width: 95%;
      //   margin: 9px 10px 8px 10px;
      //   border-radius: 10px;
      //   cursor: pointer;
      // }
      .label-text {
        color: #ffffff;
        //font-family: SuisseIntl-Book;
        font-size: 11px;
        margin: auto auto 0;
        padding-left: 11px;
      }
    }
    .button-container {
      display: flex;
      align-items: center;
      justify-content: center;
      button {
        height: 36px;
        width: auto;
        color: #ffffff !important;
        background-color: #494949 !important;
        border: 2px solid #ffffff;
        padding: 2px 23px !important;
        text-transform: none !important;
        font-size: 15px !important;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        text-decoration: none !important;
        border-radius: 10px !important;
        margin: auto;
      }
    }
    .error-message {
      text-align: center;
      font-size: 11px;
      color: #ffffff;
      margin: 0 auto;
      padding: 8px 0 0 11px;
    }
  }
}

/*** Mobile hasta 390 ***/
@media (max-width: 390px) {
  .video-options-subcontainer {
    padding: 34px 0px 10px 0px !important;
  }
}

/*** Mobile hasta 1300px ***/
@media (max-width: 1399px) {
  .video-options-container {
    width: 100%;
    margin: 23px auto 0 auto;
  }
  .video-options-subcontainer {
    padding: 34px 20px 10px 20px;
  }
  .form-container {
    display: flex !important;
    justify-content: center;
  }
  .form-container-1 {
    align-items: flex-end;
  }
  .button-container button {
    margin: 13px 0px 4px 0px !important;
  }
  .text-container {
    margin: 0px 0px 0px 10px;
    border-left: 4px solid #ffffff !important;
  }
  .radio-inputs-container {
    justify-content: center;
  }
}

/*** PC desde 1500 ***/
@media (min-width: 1400px) {
  .video-options-container {
    width: 30%;
  }
  .video-options-subcontainer {
    padding: 15px 0px 0px 0px;
  }
}
