.player-container {
  position: relative;
  // icono vol
  .volume-icon {
    margin-top: 58px;
    margin-left: 2vw;
    width: 30px;
    height: 30px;
    position: absolute;
    z-index: 2;
    background-color: white;
    opacity: 0.6;
    border-radius: 40px;
    cursor: pointer;
  }

  //cta
  //div.div-cta-button {
  //  margin: 37px auto 20px auto;
  //      text-align: center;

  //.cta-button {
  //  z-index: 2;
  //  color: white;
  //  background-color: #e25555;
  //  border-radius: 10px;
  //  border: none;
  //  cursor: pointer;
  //  width: auto;
  //  height: auto;
  //  padding: 7px 30px;
  //  margin: 10px auto 20px auto;
  //  display: flex;
  //}
  //.spot-video {
  //  position: relative;
  //  overflow: hidden;
  //  height: auto;
  //  .video-react-big-play-button {
  //    display: none;
  //  }
  //}

  .cta-button {
    position: absolute;
    z-index: 2;
    color: white;
    font-family: SuisseIntl-Book;
    background-color: #e25555;
    border-radius: 6px;
    border: none;
    cursor: pointer;
    // svg {
    //   width: 100%;
    //   height: 100%;
    //   padding: 15px;
    // }
  }
  .spot-video {
    position: relative;
    overflow: hidden;
    height: auto;
    .video-react-big-play-button {
      display: none;
    }
  }
}


// Mobile hasta 799px
@media (max-width: 575px) {
  .player-container {
    .volume-icon {
      margin-top: 40px;
      margin-left: 39px;
    }
    .cta-button {
      margin-top: calc(50% - 24vw);
      margin-left: calc(50% - 8vw);
      position: absolute;
      width: 16vw;
      height: 5.1vw;
      font-size: 1vw;
    }
  }
}


@media (min-width: 576px) and (max-width: 991px) {
  .player-container {
    .cta-button {
      margin-top: 111px;
      margin-left: 206px;
      position: absolute;
      width: 20%;
      height: 5.1vw;
      font-size: 1vw;
    }
  }
}

/*** PC desde 992 hasta 1399 ***/
@media (min-width: 992px) and (max-width: 1399px) {
  .player-container {
    .cta-button {
      margin-top: 189px;
      margin-left: 322px;
      width: 130px;
      height: 32px;
      font-size: 9px;
    }
  }
}


// PC desde 1400
@media (min-width: 1400px) {
  .player-container {
    width: 65%;
    .volume-icon {
      margin-top: 82px;
      margin-left: 39px;
      width: 50px;
      height: 50px;
      position: absolute;
      z-index: 2;
      background-color: white;
      opacity: 0.6;
      border-radius: 40px;
      cursor: pointer;
    }
    .cta-button {
      margin-top: 204px;
      margin-left: 355px;
      width: 135px;
      height: 43px;
      font-size: 9px;
    }
  }
}
