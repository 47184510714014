.cookie-consent-container {
  display: flex;
  justify-content: flex-start;
  @media (max-width: 500px) {
    justify-content: center;
  }

  .background {
    position: fixed;
    z-index: 1100;
    height: 100vh;
    width: 100vw;
    background-color: black;
    opacity: 0.5;
  }
  .banner-container {

    z-index: 1101;
    position: fixed;
    bottom: 0;
    min-height: 100px;
    width: 360px;
    margin-bottom: 20px;
    padding: 30px;
    border-radius: 5px;
    color: black;
    background-color: white;
    opacity: 0.8;
    @media (max-width: 500px) {
      width: 93%;
    }
    @media (min-width: 501px) {
      margin-left: 30px;
    }

    p {
      color: #494949;
      //font-family: Suisse_Int_l_Regular;
      font-size: 12px;
    }

    .cookie-button-container {
      display: flex;
      @media (max-width: 500px) {
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

    a {
      color: #0d6efd;
    }

    .cookie-accept-button {
      color: #ffffff;
      background-color: #096dba;
      border-radius: 6px;
      font-size: 12px;
      display: flex;
      margin: 10px 10px;
      justify-content: center;
      text-align: center;
      align-items: center;
      height: 31px;
      width: 117px;
      border: none;
    }
    .cookie-accept-button:hover {
      color: #096dba ;
      background-color: #ffffff ;
      border: none;
    }

    .cookie-settings-button {
      text-decoration: underline;
      color: #096dba ;
      background-color: #ffffff ;
      font-size: 12px ;
      //font-family: Suisse_Int_l_Bold ;
      display: flex ;
      margin: 10px 10px ;
      justify-content: center ;
      text-align: center ;
      align-items: center ;
      height: 31px;
      width: 142px;
      border: none;
      padding: 5px 0px;
    }
    .cookie-settings-button:hover {
      color: #0a5691;
      background-color: #ffffff ;
      border: none;

    }
  }
}

