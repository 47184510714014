.spot-container {
  display: flex;
}

@media (min-width: 1400px) {
  .spot-container {
    align-items: center;
  }
}

@media (max-width: 1399px) {
  .spot-container {
    flex-direction: column;
    max-width: 800px;
  }
}

@media (max-width: 991px) {
  .spot-container {
     flex-direction: column;
    max-width: 537px;
  }
}


